import React, { useEffect, useState } from 'react';
import { getRequestHttps } from '../../../libs/AllGetRequest/HttpsHelper';
import { Modal, Button } from 'react-bootstrap';
import { postOrPutRequestHttps, deleteRequestHttps } from '../../../libs/AllGetRequest/HttpsHelper';
import { useTranslation } from "react-i18next";
import moment from 'moment'; // Moment.js kütüphanesiyle tarihleri formatlamak için
import ReceiverMessagesModal from './ReceiverMessagesModal';
import { toast } from 'react-toastify';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal as AntdModal } from 'antd';

const SentMessages = ({ show, onCancel }) => {
    const [messages, setMessages] = useState([]);
    const [unreadMessage, setUnreadMessage] = useState(0);
    const [t, i18n] = useTranslation("global");
    const [visibleMessages, setVisibleMessages] = useState(5);
    const [selectedReceiver, setSelectedReceiver] = useState(null); // Mesajı gönderen kişiyi seçme
    const [showReceiverModal, setShowReceiverModal] = useState(false); // Gönderici modal'ı görüntüleme

    const fetchMessages = async () => {
        try {
            const response = await getRequestHttps(`v1/sent-messages`);
            setMessages(response);
            console.log("Gelen mesaj: ", response);
            console.log("Mesajlar: ", messages)
        } catch (error) {
            console.error('Gelen mesajlar alınırken bir hata oluştu:', error);
        }
    }

    const handleShowMore = () => {
        setVisibleMessages(prevVisible => prevVisible + 5); // 5 mesaj daha göster
    }

    const fetchUnreadMessage = async () => {
        try {
            const response = await getRequestHttps(`v1/unreadcount`);
            console.log("Message unread: ", response);
            setUnreadMessage(response);
        } catch (error) {
            console.error('Error fetching unread messages count', error);
        }
    };

    const markRead = async () => {
        try {
            await postOrPutRequestHttps(`v1/markread`, 'PUT');
            setUnreadMessage(0); //Mesajlar okundu, unReadMessage'ı sıfırla.
        } catch (error) {
            console.error('Error marking messages as read', error)
        }
    };

    const handleOpenReceiverMessages = (receiverId) => {
        const receiver = messages.find(msg => msg.receiver_id === receiverId); // Receiver_id eşleşmesini bul
        const receiverName = receiver ? receiver.receiver_name : null;
        setSelectedReceiver({ receiverId, receiverName });
        setShowReceiverModal(true);
    }

    const handleCloseReceiverMessages = () => {
        setShowReceiverModal(false);
        setSelectedReceiver(null);
    }

    const handleDelete = (receiverId) => {
        AntdModal.confirm({
            title: t("general.is_delete?"),
            content: t("general.undone"),
            okText: t("general.yes"),
            okType: "danger",
            cancelText: t("general.no"),
            zIndex: 1055,
            onOk: async () => {
                try {
                    await toast.promise(
                        deleteRequestHttps(`v1/delete-sent-messages-total?receiver_id=${receiverId}`),
                        {
                            pending: t("crud.general.deleting"),
                            success: t("crud.general.deleted"),
                            error: t("super_admin.add_error"),
                        }
                    );

                    // Mesajı listeden çıkar
                    setMessages((prevMessages) =>
                        prevMessages.filter((msg) => msg.receiver_id !== receiverId)
                    );
                } catch (error) {
                    console.error("Mesaj silinirken bir hata oluştu:", error);
                }
            },
            onCancel: () => {
                console.log("Silme işlemi iptal edildi.");
            },
        });
    };

    return (
        <Modal show={show} onHide={onCancel} onShow={fetchMessages}>
            <Modal.Header closeButton>
                <Modal.Title>{t("general.sent_messages")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {messages && messages.length > 0 ? (
                    <>
                        <ul className="list-group">
                            {messages.slice(0, visibleMessages).map(msg => (
                                <li
                                    key={msg.id}
                                    className="list-group-item list-group-item-action"
                                    style={{
                                        display: "flex", // Flexbox düzeni
                                        justifyContent: "space-between", // Sol ve sağa hizala
                                        alignItems: "center", // Dikeyde ortala
                                        cursor: "pointer",
                                    }}
                                >
                                    <div
                                        style={{ flexGrow: 1, cursor: "pointer" }}
                                        onClick={() => handleOpenReceiverMessages(msg.receiver_id)}
                                    >
                                        <small className="text-muted">
                                            {moment(msg.sent_at).format('YYYY-MM-DD HH:mm:ss')}
                                        </small>
                                        <br />
                                        <strong>{`${msg.receiver_name}`}</strong>: {msg.message}
                                    </div>
                                    <button
                                        key="delete"
                                        type="primary"
                                        icon={<DeleteOutlined />}
                                        onClick={() => handleDelete(msg.receiver_id)}
                                        style={{
                                            backgroundColor: "red",
                                            borderColor: "red",
                                            borderRadius: "10px",
                                            color: "white",
                                            width: "30px",
                                            height: "30px",
                                            display: "flex", // İkonu düzgün ortalamak için
                                            alignItems: "center", // Dikeyde ortala
                                            justifyContent: "center", // Yatayda ortala
                                            cursor: "pointer",
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </button>
                                </li>
                            ))}
                        </ul>
                        {visibleMessages < messages.length && ( // Daha fazla mesaj varsa butonu göster
                            <Button variant="link" onClick={handleShowMore}>
                                {t("general.show_more")}
                            </Button>
                        )}
                    </>
                ) : (
                    <p>{t("general.sent_messages_result")}</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="secondary"
                    onClick={() => {
                        onCancel();
                        markRead();
                    }}
                >
                    {t("general.close")}
                </Button>
            </Modal.Footer>
            {showReceiverModal && (
                <ReceiverMessagesModal
                    show={showReceiverModal}
                    onCancel={handleCloseReceiverMessages}
                    receiverId={selectedReceiver?.receiverId}
                    receiverName={selectedReceiver?.receiverName}
                />
            )}
        </Modal>
    );
};

export default SentMessages;