import React, { useState, useEffect } from 'react';
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap';
import { getRequestHttps, deleteRequestHttps, postOrPutRequestHttps } from '../../../libs/AllGetRequest/HttpsHelper';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { toast } from "react-toastify";
import { DeleteOutlined } from '@ant-design/icons';
import { Modal as AntdModal } from 'antd';
import { useAuthContext } from "../../../context/UserContext";
import { useNotificationContext } from "../../../context/NotifacitionContext";

const SenderMessagesModal = ({ show, onCancel, senderId, senderName }) => {
    const [messages, setMessages] = useState([]);
    const [t, i18n] = useTranslation("global");
    const [visibleMessages, setVisibleMessages] = useState(5);
    const [replyMode, setReplyMode] = useState(false);
    const [replyText, setReplyText] = useState("");
    const { profil } = useAuthContext();
    const { selectCompany } = useNotificationContext();
    const [user, setUser] = useState([]);

    const userInfo = async () => {
        const userData = await getRequestHttps(
            "v1/user",
            {
                company_id: selectCompany?.company_id,
            },
            "result"
        );

        const findUser = userData?.filter(user => user.id === profil.userId);
        setUser(findUser);
    }

    useEffect(() => {
        userInfo();
    }, [selectCompany?.company_id]);

    const fetchSenderMessages = async () => {
        try {
            const response = await getRequestHttps(`v1/received-messages-user?sender_id=${senderId}`);
            setMessages(response); // Gelen mesajları state'e ata
        } catch (error) {
            console.error('Gönderici mesajları alınırken bir hata oluştu:', error);
        }
    };

    const handleShowMore = () => {
        setVisibleMessages(prevVisible => prevVisible + 5); // 5 mesaj daha göster
    }

    useEffect(() => {
        if (show && senderId) {
            fetchSenderMessages();
        }
    }, [show, senderId]);

    const handleReply = async () => {
        if (!replyMode) {
            setReplyMode(true); // Reply modunu aç
            return;
        }

        if (!replyText.trim()) {
            alert(t("general.write_message_alert")); // Kullanıcı metin kutusunu boş bıraktıysa uyarı ver
            return;
        }

        try {
            const mesaj = {
                message: replyText, // Kullanıcının yazdığı mesaj
                sender_name: user[0].name_surname, // Gönderenin adı
                receiver_id: senderId, // Alıcı ID'si
                receiver_name: senderName, // Alıcı adı
                sent_at: new Date().toISOString(), // Mesajın gönderilme tarihi
                is_read: false, // Mesajın okunma durumu
            };

            await postOrPutRequestHttps(`v1/message`, 'POST', mesaj); // Mesajı gönder
            toast.success(t("general.submit_message_success")); // Başarı bildirimi
            setReplyText(""); // Metin kutusunu temizle
            setReplyMode(false); // Cevaplama modunu kapat
            console.log("User: ", user);
        } catch (error) {
            console.error('Mesaj gönderilirken bir hata oluştu: ', error);
            console.log("User: ", user);
            toast.error(t('Message could not be sent.')); // Hata bildirimi
        }
    };

    const handleDelete = (messageId) => {
        AntdModal.confirm({
            title: t("general.is_delete"),
            content: t("general.undone"),
            okText: t("general.yes"),
            okType: "danger",
            cancelText: t("general.no"),
            zIndex: 1055,
            onOk: async () => {
                try {
                    await toast.promise(
                        deleteRequestHttps(`v1/delete-received-messages?message_id=${messageId}`),
                        {
                            pending: t("crud.general.deleting"),
                            success: t("crud.general.deleted"),
                            error: t("super_admin.add_error"),
                        }
                    );

                    // Mesajı listeden çıkar
                    setMessages((prevMessages) =>
                        prevMessages.filter((msg) => msg.id !== messageId)
                    );
                } catch (error) {
                    console.error("Mesaj silinirken bir hata oluştu:", error);
                }
            },
            onCancel: () => {
                console.log("Silme işlemi iptal edildi.");
            },
        });
    };

    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{senderName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {messages && messages.length > 0 ? (
                    <>
                        <ul className="list-group">
                            {messages.map(msg => (
                                <li key={msg.id} className="list-group-item"
                                    style={{
                                        display: "flex", // Flexbox düzeni
                                        justifyContent: "space-between", // Sol ve sağa hizala
                                        alignItems: "center", // Dikeyde ortala
                                        cursor: "pointer",
                                    }}>
                                    {/* Sol taraf: Zaman ve mesaj */}
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column", // Alt alta sıralama
                                        alignItems: "flex-start", // Sol hizalı tut
                                    }}>
                                        <small className="text-muted">
                                            {moment(msg.sent_at).format('YYYY-MM-DD HH:mm:ss')}
                                        </small>
                                        <strong>{msg.message}</strong>
                                    </div>
                                    {/* Sağ taraf: Silme butonu */}
                                    <button
                                        type="primary"
                                        onClick={() => handleDelete(msg.id)}
                                        style={{
                                            backgroundColor: "red",
                                            borderColor: "red",
                                            borderRadius: "10px",
                                            color: "white",
                                            width: "30px",
                                            height: "30px",
                                            display: "flex", // İkonu düzgün ortalamak için
                                            alignItems: "center", // Dikeyde ortala
                                            justifyContent: "center", // Yatayda ortala
                                            cursor: "pointer",
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </button>
                                </li>
                            ))}
                        </ul>
                        {visibleMessages < messages.length && ( // Daha fazla mesaj varsa butonu göster
                            <Button variant="link" onClick={handleShowMore}>
                                {t("general.show_more")}
                            </Button>
                        )}
                    </>
                ) : (
                    <p>{t("Message not found.")}</p>
                )}
                {replyMode && (
                    <InputGroup className="mt-3">
                        <FormControl
                            as="textarea"
                            style={{backgroundColor: "#808080", color: "white"}}

                            placeholder={t("general.write_message")}
                            value={replyText}
                            onChange={(e) => setReplyText(e.target.value)}
                        />
                    </InputGroup>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="primary"
                    onClick={handleReply}
                    style={{
                        backgroundColor: "#24a339",
                        borderColor: "green",
                    }}
                >
                    {replyMode ? t("general.send") : t("general.reply")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SenderMessagesModal;
