import { Select, Switch } from "antd";
import { useField } from "formik";
import moment from "moment";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { DatePicker, TimePicker } from "../AntDesignPicker";

export const rangePresetsDevice = [
  {
    label: "Son 1 Gün",
    value: [moment().subtract(1, "d"), moment()],
  },
  {
    label: "Son 1 Hafta",
    value: [moment().subtract(7, "d"), moment()],
  },
  {
    label: "Son 1 Ay",
    value: [moment().subtract(30, "d"), moment()],
  },
];

export const CatchBranchValue = ({
  options,
  setSelectItem,
  id,
  setFieldValue,
  field,
  value,
}) => {
  useEffect(() => {
    setFieldValue(field, "");
  }, [id]);
  useEffect(() => {
    const data = options.filter((e) => {
      if (e?.value === value) return e;
    });
    data?.length > 0 && setSelectItem(data[0]);
  }, [value]);
  return null;
};

export const ChangeLatlong = ({ lat, long, setCityPosition }) => {
  useEffect(() => {
    if (lat && long) {
      setCityPosition([lat, long]);
    } else if (!lat || !long) {
      setCityPosition([]);
    }
  }, [lat, long]);

  return null;
};

export const ResetFormField = ({ setFieldValue, field, id }) => {
  useEffect(() => {
    field && setFieldValue(field, "");
  }, [id]);
  return null;
};

// export const SelectDepartmentList = ({
//   selectDepartment,
//   selectBranch,
//   setFieldValue,
//   departmentList,
//   setDepartmentOptions,
// }) => {
//   useEffect(() => {
//     const data = departmentList?.filter((item) =>
//       selectBranch?.includes(item.branch_id)
//     );
//     setDepartmentOptions(data);

//     // const dataDepartment = data.filter((item) =>
//     //   selectDepartment?.includes(item.value)
//     // );

//     // setFieldValue("department", []);
//   }, [selectBranch]);
//   return null;
// };

export const SelectDepartmentList = ({
  selectDepartment,
  selectBranch,
  setFieldValue,
  departmentList,
  setDepartmentOptions,
}) => {
  useEffect(() => {
    // selectBranch bir dizi mi kontrol edin
    const branches = Array.isArray(selectBranch) ? selectBranch : [selectBranch];
    const departments = Array.isArray(selectDepartment) ? selectDepartment : [selectDepartment];

    // departmentList'i filtreleyin
    const data = departmentList?.filter((item) =>
      branches.includes(item.branch_id),
    );

    // Filtrelenmiş departmentList'i setDepartmentOptions ile ayarlayın
    setDepartmentOptions(data);

    // selectDepartment değerini kontrol edip array'e sarın
    // if (selectBranch && selectBranch.length > 0) {
    //   setFieldValue("department", []);  // department'ı sıfırla
    // }
    // if (selectDepartment) {
    //   const selectedDepartmentArray = Array.isArray(selectDepartment) ? selectDepartment : [selectDepartment];
    //   setFieldValue("department", selectedDepartmentArray);
    // }
  }, [selectBranch, selectDepartment, departmentList, setFieldValue]);

  return null;
};

export const PhoneNumberField = (props) => {
  const [field, state, { setValue, setTouched }] = useField(props.field.name);

  const onChange = (value) => {
    setValue(value);
  };
  return (
    <PhoneInput
      {...props}
      style={{ height: "48px", color: "#f5f1f1" }}
      placeholder="Enter phone number"
      value={field.value}
      onChange={onChange}
    />
  );
};

export const MultiSelectField = (props) => {
  const [field, , { setValue }] = useField(props.field.name);

  const handleChange = (value) => {
    setValue(value);
    // Eğer `props.onChange` varsa, çağırarak dışarıya değer gönderin
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Select
      {...props}
      name={field.name}
      options={props.options}
      bordered={false}
      size="middle"
      value={field.value}
      showSearch={props.search}
      allowClear={props.clear}
      onChange={handleChange}
    />
  );
};

export const SingleSelectField = (props) => {
  const [field, state, { setValue, setTouched }] = useField(props.field.name);

  const onChange = (value) => {
    const selectedValueArray = value ? [value] : [];
    setValue(selectedValueArray);
  };

  return (
    <Select
      {...props}
      name={field.name}
      options={props.options}
      bordered={false}
      size="middle"
      value={field.value[0] || null}
      showSearch={props.search}
      allowClear={props.clear}
      onChange={onChange}
    />
  );
};

// export const SingleSelectField = (props) => {
//   const [field, state, { setValue, setTouched }] = useField(props.field.name);

//   const onChange = (value) => {
//     setValue(value);
//   };

//   return (
//     <Select
//       {...props}
//       name={field.name}
//       options={props.options}
//       bordered={false}
//       size="middle"
//       value={field.value}
//       showSearch={props.search}
//       allowClear={props.clear}
//       onChange={onChange}
//     />
//   );
// };


export const SwitchField = (props) => {
  const [field, state, { setValue, setTouched }] = useField(props.field.name);

  const onChange = (value) => {
    setValue(value);
  };

  return <Switch {...props} onChange={onChange} />;
};

export const ChangeTier2Status = ({
  field,
  value,
  setFieldValue,
  setValue,
}) => {
  useEffect(() => {
    if (!value) {
      setFieldValue(field, false);
    } else {
      setFieldValue(field, setValue);
    }
  }, [value, setValue]);
};

export const TimePickerField = (props) => {
  const [field, state, { setValue, setTouched }] = useField(props.field.name);

  const onChanges = (time, timeString) => {
    setValue(timeString);
  };
  return <TimePicker {...props} name={field.name} onChange={onChanges} />;
};

export const DatePickerField = (props) => {
  const [field, state, { setValue, setTouched }] = useField(props.field.name);

  const onChanges = (time, timeString) => {
    setValue(timeString);
  };
  return (
    <DatePicker showTime {...props} name={field.name} onChange={onChanges} />
  );
};

export const generateRandomDate = (from, to) => {
  return new Date(
    from.getTime() + Math.random() * (to.getTime() - from.getTime())
  );
};

// image upload

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    toast.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    toast.error("Image must smaller than 1MB!");
  }
  return isJpgOrPng && isLt2M;
};

export const convertTimeToMinutes = (timeValue) => {
  const [hours, minutes] = timeValue.split(":").map(Number);

  const totalMinutes = hours * 60 + minutes;

  return totalMinutes;
};

export const convertMinutesToTime = (totalMinutes) => {
  const hours = Math.ceil(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const timeString = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;

  return timeString;
};

export const calculateTotalTime = (dataArray, overTime) => {
  let trueTime = 0;
  let falseTime = 0;

  overTime
    ? dataArray.forEach((item) => {
      const loginTime = new Date(item.login_time).getTime();
      const logoutTime = new Date(item.logout_time).getTime();
      const timeDiff = logoutTime - loginTime;
      timeDiff > 0 ? (trueTime += timeDiff) : (trueTime += 0);
    })
    : dataArray.forEach((item, index) => {
      if (item.status === "pending") {
        const loginBreakTime = new Date(item.logout_time).getTime();
        const logoutBreakTime = new Date(
          dataArray[index + 1]?.login_time
        ).getTime();

        const timeDiff = logoutBreakTime && logoutBreakTime - loginBreakTime;

        timeDiff > 0 ? (falseTime += timeDiff) : (falseTime += 0);
      }
    });

  return overTime ? trueTime : falseTime;
};

export const calculateDayWorkingHour = (dataArray, ms) => {
  let trueTime = 0;
  let falseTime = 0;

  dataArray.forEach((item) => {
    const loginTime = new Date(item.login_time).getTime();
    const logoutTime = new Date(item.logout_time).getTime();
    const timeDiff = logoutTime - loginTime;

    if (item.status === true) {
      timeDiff > 0 ? (trueTime += timeDiff) : (trueTime += 0);
    } else {
      falseTime += timeDiff;
    }
  });

  const formatTime = (time) => {
    var totalMinutes = Math.ceil(time / (1000 * 60));
    var hours = Math.ceil(totalMinutes / 60);
    // var minutes = totalMinutes % 60;

    // return ms ? totalMinutes : hours;
    return hours;
  };

  return formatTime(trueTime);
};

export const formatTime = (time, lang) => {
  const totalHours = Math.floor(time);
  const totalMinutes = Math.round((time - totalHours) * 60);

  return totalHours > 0
    ? `${totalHours} ${lang("general.hours")} ${totalMinutes} ${lang("general.minute")}`
    : `${totalMinutes} ${lang("general.minute")}`;
};

export const userLogType = (value, t) => {
  if (value == 100) {
    return t("staff_log.user_create");
  } else if (value == 101) {
    return t("staff_log.password_changed");
  } else if (value == 102) {
    return t("staff_log.permission_request_sent");
  } else if (value == 103) {
    return t("staff_log.permission_denied");
  } else if (value == 104) {
    return t("staff_log.permission_accepted");
  } else if (value == 105) {
    return t("staff_log.wrong_area");
  } else if (value == 106) {
    return t("staff_log.branch_holiday");
  } else if (value == 107) {
    return t("staff_log.department_holiday");
  } else if (value == 108) {
    return t("staff_log.personel_holiday");
  } else if (value == 109) {
    return t("staff_log.personel_work_holiday");
  } else {
    return "";
  }
};

/* day different function */

export const calculateDaysBetweenDates = (date1, date2) => {
  const parsedDate1 = new Date(date1);
  const parsedDate2 = new Date(date2);

  const millisecondsDate1 = parsedDate1.getTime();
  const millisecondsDate2 = parsedDate2.getTime();

  const dayDifference =
    Math.abs(millisecondsDate2 - millisecondsDate1) / (1000 * 60 * 60 * 24);
  return date1 === date2 ? dayDifference + 1 : dayDifference;
};

export const calculateHoursOverTime = (data) => {
  // console.log("data", data);
  let totalWorkMinutes = 0;
  data.forEach((entry) => {
    let loginTime = new Date(entry.login_time);
    let logoutTime = new Date(entry.logout_time);

    if (
      !entry.logout_time ||
      (logoutTime.getHours() < 18 &&
        loginTime.getHours() < 18 &&
        loginTime.getHours() > 6 &&
        logoutTime.getHours() > 6)
    ) {
      return;
    }

    if (loginTime.getHours() < 18 && logoutTime.getHours() >= 18) {
      const newDate = loginTime;
      newDate.setHours(18, 0, 0);
      const result = logoutTime - newDate;
      totalWorkMinutes += result;
    } else if (loginTime.getHours() >= 18 && logoutTime.getHours() >= 18) {
      const result = logoutTime - loginTime;
      totalWorkMinutes += result;
    }

    if (loginTime.getHours() < 6 && logoutTime.getHours() >= 6) {
      const newDate = logoutTime;
      newDate.setHours(6, 0, 0);
      const result = newDate - loginTime;
      totalWorkMinutes += result;
    } else if (loginTime.getHours() < 6 && logoutTime.getHours() <= 6) {
      const result = logoutTime - loginTime;
      totalWorkMinutes += result;
    }
  });
  // Saat ve dakika cinsine dönüştürme
  return totalWorkMinutes;
};

export const calculateBreakHoursOverTime = (data) => {
  let totalWorkMinutes = 0;
  data.forEach((entry, index) => {
    let loginTime =
      entry.status === "pending"
        ? new Date(data[index + 1]?.login_time)
        : new Date(entry.login_time);
    let logoutTime = new Date(entry.logout_time);

    if (
      !entry.status != "pending" ||
      !entry.logout_time ||
      (logoutTime.getHours() < 18 &&
        loginTime.getHours() < 18 &&
        loginTime.getHours() > 6 &&
        logoutTime.getHours() > 6)
    ) {
      return;
    }

    if (loginTime.getHours() < 18 && logoutTime.getHours() >= 18) {
      const newDate = loginTime;
      newDate.setHours(18, 0, 0);
      const result = logoutTime - newDate;
      totalWorkMinutes += result;
    } else if (loginTime.getHours() >= 18 && logoutTime.getHours() >= 18) {
      const result = logoutTime - loginTime;
      totalWorkMinutes += result;
    }

    if (loginTime.getHours() < 6 && logoutTime.getHours() >= 6) {
      const newDate = logoutTime;
      newDate.setHours(6, 0, 0);
      const result = newDate - loginTime;
      totalWorkMinutes += result;
    } else if (loginTime.getHours() < 6 && logoutTime.getHours() <= 6) {
      const result = logoutTime - loginTime;
      totalWorkMinutes += result;
    }
  });
  // Saat ve dakika cinsine dönüştürme
  return totalWorkMinutes;
};

/* day different function */

/* group data personel detail */

export const groupByDate = (data) => {
  const groupedData = [];

  data?.forEach((entry) => {
    const date = entry?.login_datetime?.split("T")[0];

    if (!groupedData[date]) {
      groupedData[date] = [];
    }

    groupedData[date].push(entry);
  });

  return groupedData;
};

// export const groupDataByDate = (data) => {
//   const formatDate = (dateString) =>
//     new Date(dateString).toLocaleDateString("en-US", {
//       month: "2-digit",
//       day: "2-digit",
//     });
//   const groupedData = {};

//   data.forEach((entry) => {
//     const dateKey = formatDate(entry.created_at);

//     groupedData[dateKey] = groupedData[dateKey] || {
//       date: entry.created_at,
//       value: [],
//     };

//     groupedData[dateKey].value.unshift(entry);
//   });

//   return Object.values(groupedData);
// };

export const groupDataByDate = (data) => {
  const formatDate = (dateString) =>
    new Date(dateString).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
    });
  const groupedData = {};

  data.forEach((entry) => {
    const dateKey = formatDate(entry.login_datetime);

    if (!groupedData[dateKey]) {
      groupedData[dateKey] = {
        date: entry.login_datetime,
        value: [],
      };
    }

    groupedData[dateKey].value.push(entry);
  });

  return Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));
};

export const groupDataByDateAndUserId = (data) => {
  const formatDate = (dateString) =>
    new Date(dateString).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
    });
  const groupedData = {};

  data.forEach((entry) => {
    const dateKey = formatDate(entry.created_at);

    if (!groupedData[dateKey]) {
      groupedData[dateKey] = {
        date: entry.created_at,
        value: [],
      };
    }

    groupedData[dateKey].value.push(entry);
  });

  // Gruplanmış verileri sıralama
  Object.values(groupedData).forEach((group) => {
    group.value.sort((a, b) => a.user_id - b.user_id);
  });

  return Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));
};

export const calculateWorkedHours = (dayData) => {
  let totalMilliseconds = 0;
  let lastLoginTime = null;

  for (const entry of dayData) {
    if (entry.is_record_login && !lastLoginTime) {
      lastLoginTime = new Date(entry.record_datetime);
    } else if (!entry.is_record_login && lastLoginTime) {
      const logoutTime = new Date(entry.record_datetime);
      totalMilliseconds += logoutTime.getTime() - lastLoginTime.getTime();
    }
  }

  return (totalMilliseconds / (1000 * 60 * 60)).toFixed(1);
};

export function processLoginLogoutData(records) {
  const processedData = [];

  records.forEach((record) => {
    if (record != undefined) {

      const loginDateTime = new Date(record.login_datetime);
      let logoutDateTime = new Date(record.logout_datetime);
      // Eğer login ve logout tarihleri farklıysa, araya yeni bir obje ekle
      if (record.login_datetime && !record.logout_datetime) {
        processedData.push(record);
        return;
      }
      if (loginDateTime.getDate() !== logoutDateTime.getDate()) {
        const newRecord = {
          id: record.id,
          user_id: record.user_id,
          company_id: record.company_id,
          created_at: record.created_at,
          description: record.description,
          status_type: record.status_type,
          ip_address: record.ip_address,
          user_agent: record.user_agent,
          login_datetime: record.login_datetime, // Gün başına eşitle (00:01)
          is_logout_break: record.is_logout_break,
          updater_id: record.updater_id,
          updated_at: record.updated_at,
          user_name: record.user_name,
          creator_user: record.creator_user,
          updater_user: record.updater_user,
          department_name: record.department_name,
          branch_name: record.branch_name,
          logout_datetime: new Date(loginDateTime.setHours(23, 59, 0, 0)), // Gün sonuna eşitle (23:59)
          branch_ids: record.branch_ids,
          department_ids: record.department_ids,
          branch_id: record.branch_id,
          department_id: record.department_id,
        };
        processedData.push(newRecord);

        // Yeni bir obje oluştur ve işlemleri gerçekleştir
        const nextDayRecord = {
          id: record.id,
          user_id: record.user_id,
          company_id: record.company_id,
          created_at: moment(record.created_at).add(1, "day"),
          description: record.description,
          status_type: record.status_type,
          ip_address: record.ip_address,
          user_agent: record.user_agent,
          login_datetime: new Date(logoutDateTime.setHours(0, 1, 0, 0)), // Gün başına eşitle (00:01)
          is_logout_break: record.is_logout_break,
          updater_id: record.updater_id,
          updated_at: record.updated_at,
          user_name: record.user_name,
          creator_user: record.creator_user,
          updater_user: record.updater_user,
          department_name: record.department_name,
          branch_name: record.branch_name,
          logout_datetime: record.logout_datetime, // Günün sonuna eşitle (05:59)
          branch_ids: record.branch_ids,
          department_ids: record.department_ids,
          branch_id: record.branch_id,
          department_id: record.department_id,
        };
        processedData.push(nextDayRecord);
      } else {
        // Farklı günlerde değilse, kaydı direkt olarak ekleyin
        processedData.push(record);
      }
    }
  }
  );

  return processedData;
}

/* admin group company */

export const groupById = (data) => {
  const groupedData = {};

  data.forEach((item) => {
    const { user_id, name_surname, created_at, cretor_user, email, ...rest } =
      item;

    if (!groupedData[user_id]) {
      groupedData[user_id] = {
        user_id,
        name_surname,
        created_at,
        cretor_user,
        email,
        value: [],
      };
    }

    groupedData[user_id].value.push(rest);
  });

  return Object.values(groupedData);
};

/* admin group company */

/* group data personel detail */

// export const flattenWorkCalendar = (data) => {
//   const allWorkCalendars = [];

//   Array.isArray(data?.work_calender) &&
//     data?.work_calender?.forEach((workPlanDetails) => {
//       allWorkCalendars.push(workPlanDetails);
//     });

//   // Iterate over branches
//   data?.branches?.forEach((branch) => {
//     if (branch.work_calender) {
//       allWorkCalendars.push(...branch.work_calender);
//     }
//   });

//   // Iterate over departments
//   data?.departments?.forEach((department) => {
//     if (department.work_calender) {
//       allWorkCalendars.push(...department.work_calender);
//     }
//   });

//   return allWorkCalendars;
// };

export const checkDateOverlap = (
  date1, // selecet start
  date2, // selecet end
  date3, // leave start
  date4
  // leave end
) => {
  return (
    moment(date1).isBetween(date3, date4) ||
    moment(date2).isBetween(date3, date4) ||
    moment(date3).isBetween(date1, date2) ||
    moment(date4).isBetween(date1, date2)
  );
};
