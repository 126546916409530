import * as Yup from "yup";

export const addCompaniesSchema = (t, edit, station) => {
  const schema = Yup.object().shape({
    company_name: Yup.string().required(t("general.field_error")),
    // max_stuff_count: Yup.string().required(t("general.field.error")),
    // subscription_plan_id: Yup.string().required(t("general.field.error")),
    lat: Yup.string().required(t("general.field_error")),
    long: Yup.string().required(t("general.field_error")),
    tax_number: Yup.string().required(t("general.field_error")),
    radius: Yup.number()
      .required(t("general.field_error"))
      .min(50, t("branches.radius_min"))
      .max(250, t("branches.radius_max")),

    manager: station && Yup.array().min(1, t("general.field_error")),
    manager_name:
      !station && !edit && Yup.string().required(t("general.field_error")),
    manager_email:
      !station &&
      !edit &&
      Yup.string()
        .email(t("general.email_error"))
        .required(t("general.field_error")),
    manager_password:
      !station &&
      !edit &&
      Yup.string()
        .min(6, t("general.min_six"))
        .required(t("general.field_error")),
    manager_confirm_password:
      !station &&
      Yup.string()
        .when("manager_password", {
          is: (val) => {
            return val;
          },
          then: (e) => e.required(t("general.field_error")),
        })
        .oneOf([Yup.ref("manager_password")], t("general.confirm_password")),
  });
  return schema;
};

export const addCompanySchema = (t) => {
  const schema = Yup.object().shape({
    company_name: Yup.string().required(t("general.field_error")),
    radius: Yup.number()
      .required(t("general.field_error"))
      .min(50, t("branches.radius_min"))
      .max(250, t("branches.radius_max")),

  });
  return schema;
};

export const addBrancSchema = (t) => {
  return Yup.object().shape({
    branch_name: Yup.string().required(t("general.field_error")),
    lat: Yup.number().when(['branch_name', 'location_tracking'], {
      is: (branch_name, location_tracking) => branch_name === 'Home' || location_tracking === false,
      then: (schema) => schema.nullable().test('is-null', t("general.must_be_null"), (value) => value === null || value === undefined),
      otherwise: (schema) => schema.required(t("general.field_error")),
    }),
    long: Yup.number().when(['branch_name', 'location_tracking'], {
      is: (branch_name, location_tracking) => branch_name === 'Home' || location_tracking === false,
      then: (schema) => schema.nullable().test('is-null', t("general.must_be_null"), (value) => value === null || value === undefined),
      otherwise: (schema) => schema.required(t("general.field_error")),
    }),
    radius: Yup.number().when(['branch_name', 'location_tracking'], {
      is: (branch_name, location_tracking) => branch_name === 'Home' || location_tracking === false,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(50, t("branches.radius_min")).max(250, t("branches.radius_max")),
    }),
  });
};

export const addDepartmentSchema = (t) => {
  const schema = Yup.object().shape({
    department_name: Yup.string().required(t("general.field_error")),
    branch: Yup.string().required(t("general.field_error")),
    lat: Yup.number()
      .label("lat")
      .when("location", {
        is: false,
        then: () => Yup.number().required(t("general.field_error")),
      }),
    long: Yup.number().when("location", {
      is: false,
      then: () => Yup.number().required(t("general.field_error")),
    }),
    radius: Yup.number().when("location", {
      is: false,
      then: () =>
        Yup.number()
          .min(50, t("branches.radius_min"))
          .max(250, t("branches.radius_max"))
          .required(t("general.field_error")),
    }),
  });
  return schema;
};

export const addPersonnelSchema = (t, edit) => {
  const schema = Yup.object().shape({
    personnel_name: Yup.string().required(t("general.field_error")),
    branch: Yup.number()
      .transform((value, originalValue) => {
        // Eğer gelen değer bir boş dizi ise null'a çevir
        return Array.isArray(originalValue) && originalValue.length === 0 ? null : value;
      })
      .nullable() // null değeri geçerli hale getirir
      .when("type_id", {
        is: (type_id) => type_id !== 1,
        then: (schema) => schema.required(t("general.field_error")),
        otherwise: (schema) => schema.nullable(),
      }),
    department: Yup.number()
      .transform((value, originalValue) => {
        return Array.isArray(originalValue) && originalValue.length === 0 ? null : value;
      })
      .nullable()
      .when("type_id", {
        is: (type_id) => type_id !== 1,
        then: (schema) => schema.required(t("general.field_error")),
        otherwise: (schema) => schema.nullable(),
      }),
    email: Yup.string()
      .email(t("general.email_error"))
      .matches(/@[^.]*\./, t("general.email_error"))
      .required(t("general.field_error")),
    // position: Yup.string().required(t("general.field_error")),
    // branch: Yup.string().required(t("general.field_error")),
    // department: Yup.string().required(t("general.field_error")),
    password:
      !edit &&
      Yup.string()
        .min(6, t("general.min_six"))
        .required(t("general.field_error")),
    confirm_password: Yup.string()
      .when("password", {
        is: (val) => {
          return val;
        },
        then: (e) => e.required(t("general.field_error")),
      })
      .oneOf([Yup.ref("password")], t("general.confirm_password")),
  });
  return schema;
};

export const addSuperUserSchema = (t) => {
  const schema = Yup.object().shape({
    name_surname: Yup.string().required(t("general.field_error")),
    email: Yup.string()
      .email(t("general.email_error"))
      .required(t("general.field_error")),
    password: Yup.string()
      .min(6, t("general.min_six"))
      .required(t("general.field_error")),
    confirm_password: Yup.string()
      .required(t("general.field_error"))
      .oneOf([Yup.ref("password")], t("general.confirm_password")),
  });
  return schema;
};

export const forgotPasswordSchema = (t) => {
  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t("general.email_error"))
      .required(t("general.field_error")),
  });
  return schema;
};

export const manuelLoginLogoutSchema = (t) => {
  const schema = Yup.object().shape({
    record_datetime: Yup.string().required(t("general.field_error")),
  });
  return schema;
};

export const manuelLeaveChangeStatus = (t) => {
  const schema = Yup.object().shape({
    status: Yup.string().required(t("general.field_error")),
    leave_type_id: Yup.string().required(t("general.field_error")),
  });
  return schema;
};

export const addLeavesSchema = (t) => {
  const schema = Yup.object().shape({
    // label_en: Yup.string().required(t("general.field_error")),
  });
  return schema;
};

export const WorkFormValidationSchema = (locationName) => Yup.object().shape({
  branch: Yup.string().when([], {
    is: () => locationName === 'branches',
    then: Yup.string().required('Branch is required.'),
    otherwise: Yup.string().nullable(),
  }),
  department: Yup.string().when([], {
    is: () => locationName === 'department',
    then: Yup.string().required('Department is required.'),
    otherwise: Yup.string().nullable(),
  }),
  start_date: Yup.date().required('Start date is required.'),
  end_date: Yup.date().required('End date is required.'),
});

