import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Select,
  Space,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'antd/lib/modal/Modal';  // Ant Design Modal kullanımı
import MessageModal from "./MessageModal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TableComponents from "../../Table/TableComponents";
import MessageIcon from "../../icons/General/MessageIcon";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import { useAuthContext } from "../../../context/UserContext";
import {
  postOrPutRequestHttps,
  getRequestHttps,
} from "../../../libs/AllGetRequest/HttpsHelper";

const SubmitMessage = () => {
  const { profil } = useAuthContext();
  console.log("profil userType: ", profil.userType);
  const [t, i18n] = useTranslation("global");
  const { isEditMode, setTitle, selectCompany } = useNotificationContext();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [openModal, setOpenModal] = useState(false); // Modal açılma durumunu kontrol eden state
  const [loading, setLoading] = useState(false);
  const [personel, setPersonel] = useState([]);
  const [personelSearchList, setPersonelSearchList] = useState([]);
  const [tableColumn, setTableColumn] = useState("");
  const [manager, setManager] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [userId, setUserId] = useState(0);
  const [selectedUser, setSelectedUser] = useState(0);
  // const [department, setDepartment] = useState([]);
  // const [branch, setBranch] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [station, setStation] = useState(0);
  const searchInput = useRef(null);
  const { Search } = Input;
  const userInfo = async () => {
    const userData = await getRequestHttps(
      "v1/user",
      {
        company_id: selectCompany?.company_id,
      },
      "result"
    );

    console.log('userData: ', userData);
    console.log("profil: ", profil);

    const findManager = userData?.filter(user => user.type_id === 1);
    if (findManager.length > 0) {
      const matchingManager = findManager.find(user => user.id === profil.userId);
      if (matchingManager) {
        setManager(matchingManager);
      }
    }

    const findEmployee = userData?.filter(user => user.type_id === 2);
    if (findEmployee.length > 0) {
      const matchingEmployee = findEmployee.find(user => user.id === profil.userId);
      if (matchingEmployee) {
        setEmployee(matchingEmployee);
      }
    }
  }

  useEffect(() => {
    userInfo();
  }, [selectCompany?.company_id]);

  useEffect(() => {
    setTitle([t("general.submit_message")]);
  }, [i18n.language]);

  // const getBranchName = (targetIds, list) => {
  //   if (targetIds?.length === 0 || list?.length === 0) {
  //     return [];
  //   }
  //   return targetIds?.map((id) => {
  //     const foundItem = list?.find((item) => item.value === id);
  //     return foundItem ? foundItem.label : null;
  //   });
  // };

  // const getDepartmentName = (targetIds, list) => {
  //   if (targetIds?.length === 0 || list?.length === 0) {
  //     return [];
  //   }
  //   return targetIds?.map((id) => {
  //     const foundItem = list?.find((item) => item.value === id);
  //     return foundItem ? foundItem.label : null;
  //   });
  // };


  const getPersonel = async () => {
    setLoading(true);
    let data;

    try {
      data = await getRequestHttps(
        "v1/user",
        {
          company_id: selectCompany?.company_id,
        },
        "result"
      );

      // const branchList =
      //   selectCompany?.branch &&
      //   (await getRequestHttps("v1/branch", {
      //     company_id: selectCompany?.company_id,
      //   }));
      // const optionsBranch = branchList?.map((e) => {
      //   return {
      //     label: e?.name,
      //     value: e?.id,
      //   };
      // });

      // const departmentList =
      //   selectCompany?.department &&
      //   (await getRequestHttps("v1/department", {
      //     company_id: selectCompany?.company_id,
      //   }));
      // const optionsDepartment = departmentList?.map((e) => {
      //   return {
      //     label: e?.name,
      //     value: e?.id,
      //   };
      // });

      // setDepartment(optionsDepartment);
      // setBranch(optionsBranch);

      // const manager = await getRequestHttps("v1/user-managers", {
      //   company_id: selectCompany?.company_id,
      // })
      // const managerValues = manager?.map((e) => {
      //   return {
      //     field1: e?.name_surname,
      //     field2: e?.email,
      //     company: e?.company_id,
      //     serial: e?.id,
      //     id: e?.id,
      //   }
      // });

      // setManager(managerValues);
      // console.log('managerValues: ', managerValues);

      const personelList = data?.filter(user => user.type_id !== profil.userType).map((e, i) => {
        return {
          field1: e?.name_surname,
          field2: e?.email,
          field3: e?.work_title,
          field4: e?.type_id,
          logStatus: e?.is_record_login ? true : false,
          serial: e?.id,
          // branch:
          //   e?.branch_ids?.length > 0
          //     ? getBranchName(e?.branch_ids, optionsBranch)
          //     : "",
          // department:
          //   e?.department_ids?.length > 0
          //     ? getDepartmentName(e?.department_ids, optionsDepartment)
          //     : "",
          id: e?.id,
        };
      });
      console.log("Kullanıcı datası: ", data)
      setPersonel(personelList);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearchStaff = async (value) => {
    setLoading(true);
    let data;
    try {
      data = await getRequestHttps(
        "v1/user",
        {
          company_id: selectCompany?.company_id,
          keyword: value,
        },
        "result"
      );
      console.log("Arama sonucu", data)
      const personelList = data?.filter(user => user.type_id !== profil.userType).map((e, i) => {
        return {
          field1: e?.name_surname,
          field2: e?.email,
          field3: e?.work_title,
          field4: e?.type_id,
          logStatus: e?.is_record_login ? true : false,
          serial: e?.id,
          // branch:
          //   e?.branch_ids?.length > 0
          //     ? getBranchName(e?.branch_ids, branch)
          //     : "",
          // department:
          //   e?.department_ids?.length > 0
          //     ? getDepartmentName(e?.department_ids, department)
          //     : "",
          id: e?.id,
        };
      });
      console.log("Filtrelenmiş personel listesi: ", personelList)
      setPersonelSearchList(personelList);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPersonel();
  }, [selectCompany?.company_id]);

  useEffect(() => {
    searchValue?.length > 3 && handleSearchStaff(searchValue);
  }, [searchValue]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  const getColumnSearchProps = (dataIndex, selectOptions) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        {selectOptions && (
          <Select
            ref={searchInput}
            placeholder={t("general.reset")}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          >
            {selectOptions.map((option, key) => {
              return (
                <Select.Option key={key} value={`${option.value}`}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        )}
        {!selectOptions && (
          <Input
            ref={searchInput}
            placeholder={t("general.search")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            onInput={() => {
              setSearchedColumn(dataIndex);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}
        <Space>
          <Button
            type="link"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("general.search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 80,
            }}
          >
            {t("general.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (!value || !record[dataIndex]) return;
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  });

  const handleSendMessage = async (message) => {
    if (!selectedUser) {
      alert('Please select a user to send a message to.');
      return;
    }
    try {
      const senderName = profil.userType === 2 ? employee.name_surname : manager.name_surname; //  Eğer profil.userType 2 ise employee.name_surname, değilse manager.name_surname kullanılır.
      const msg = {
        message: message,
        sender_name: senderName,
        receiver_id: selectedUser.user_id,
        receiver_name: selectedUser.name,
        sent_at: new Date().toISOString(),
        is_read: false
      };
      console.log("Mesaj: ", msg);
      console.log("Gönderen: ", senderName);
      console.log("Alıcı: ", selectedUser);
      await postOrPutRequestHttps(`v1/message`, 'POST', msg);
      toast.success(t("general.submit_message_success"));
      setOpenModal(false); // Mesaj gönderildikten sonra formu kapat
    } catch (error) {
      console.error('An error occurred while sending the message: ', error);
      console.log('manager: ', manager)
      toast.alert('Message could not be sent.');
    }
  };

  const columns = [
    {
      title: t("general.name"),
      dataIndex: "field1",
      ...getColumnSearchProps("field1"),
      render: (_, { field1, serial }) => (
        <div className="tableRowText text-[#407BFF]  hover:text-[#009BDA] duration-300 capitalize">
          <button onClick={() => {
            setSelectedUser({ user_id: serial, name: field1 });
            setOpenModal(true); // Mesaj modalını açar
          }}
            className="tableActionIcon">
            <Tooltip title={t("general.message")}>
              <MessageIcon width={20} height={20} />
            </Tooltip>
          </button>
          <Link state={{ name: field1 }} style={{ marginLeft: '20px' }}>
            {field1}
          </Link>
        </div>
      ),
    },
    {
      title: t("staff.staff_email"),
      dataIndex: "field2",
      ...getColumnSearchProps("field2"),
      render: (text) => (
        <div className="tableRowText normal-case">{text || ""}</div>
      ),
    },
    {
      title: t("staff.title"),
      dataIndex: "field3",
      ...getColumnSearchProps("field3"),
      render: (text) => <div className="tableRowText">{text || ""}</div>,
    },
    {
      title: t("staff.type"),
      dataIndex: "field4",
      ...getColumnSearchProps("field4", [
        { value: "1", label: t("companies.manager") },
        { value: "2", label: t("sidebar.staff") },
      ]),
      render: (text) => (
        <div className="tableRowText">
          <Tag color={text == "2" ? "yellow" : "blue"}>
            {text == "2" ? t("sidebar.staff") : t("companies.manager")}
          </Tag>
        </div>
      ),
    },
    // {
    //   title: selectCompany?.branch ? t("sidebar.branches") : null,
    //   dataIndex: "branch",
    //   ...getColumnSearchProps("branch"),
    //   render: (text) => (
    //     <div className="tableRowText">
    //       {text?.length > 0 ? text?.map((item, index) => <p key={index}>{item}</p>) : "-"}
    //     </div>
    //   ),
    // },
    // {
    //   title: selectCompany?.department ? t("sidebar.department") : null,
    //   dataIndex: "department",
    //   ...getColumnSearchProps("department"),
    //   render: (text) => (
    //     <div className="tableRowText">
    //       {text?.length > 0 ? text?.map((item, index) => <p key={index}>{item}</p>) : "-"}
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    const data = columns.filter((item) => item?.title);
    const newColumns = isEditMode ? data : data.slice(0, -2);
    setTableColumn(newColumns);
  }, [isEditMode, i18n.language, selectCompany?.company_id]);

  return (
    <>
      {/* MessageModal bileşenini doğrudan kullanıyoruz */}
      <MessageModal
        onSendMessage={handleSendMessage} // Mesaj gönderme fonksiyonu
        openModal={openModal} // Modal açılma durumu
        setOpenModal={setOpenModal} // Modal kapatma fonksiyonu
      />
      <div className="mt-5 pl-4 pr-5">
        <TableComponents
          scroll={personel?.length > 1000 && { y: 700 }}
          title={false}
          bordered={false}
          loading={loading}
          size="large"
          pagination={false}
          columns={tableColumn}
          data={searchValue?.length > 3 ? personelSearchList : personel}
        />
      </div>
    </>
  );
}

export default SubmitMessage;
