import moment from "moment";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Input, Popconfirm, Select, Space, Tooltip } from "antd";
import PersonelHeader from "./PersonelDetail/PersonelHeader";
import { useNotificationContext } from "../../../context/NotifacitionContext";
import { DatePicker } from "../../../helpers/AntDesignPicker";
import {
  formatTime,
  processLoginLogoutData,
  groupDataByDate,
  rangePresetsDevice,
} from "../../../helpers/Function/Function";
import ExcelExport from "../../../helpers/ExcelExport/excelExport";
import { getRequestHttps } from "../../../libs/AllGetRequest/HttpsHelper";
import TableComponents from "../../Table/TableComponents";
import ReportTimeHelper from "../Report/ReportTimeHelper";
import { ErrorMessage, Form, Formik } from "formik";
import { useLocation } from "react-router-dom";

const ExcelData = ({ data, date, language, station }) => {
  const [t, i18n] = useTranslation("global");
  const [dataArr, setDataArr] = useState();

  useEffect(() => {
    const dataArr = data?.map((item) => {
      return station == 1
        ? {
          lastSeen: item.user_name,
          field1: moment(item.created_at).format("DD-MM-YYYY"),
          field2: formatTime(item.assigned_work_time, t),
          field3: formatTime(item.work_time, t),
          field4: formatTime(item.assigned_break, t),
          field5: formatTime(item.used_break, t),
          field6: formatTime(item.assigned_net_work_hours, t),
          field7: formatTime(item.net_work_hours, t),
        }
        : station == 0 ? {
          lastSeen: item.user_name,
          field1: moment(item.created_at).format("DD-MM-YYYY HH:mm"),
          field2: moment(item.login_time).format("HH:mm:ss"),
          field3: moment(item.logout_time).format("HH:mm:ss"),
        }
          : station == 2 ? {
            lastSeen: item.user_name,
            field1: `${moment(date.start).format("DD-MM-YYYY")} - ${moment(date.end).format("DD-MM-YYYY")}`,
            field2: formatTime(item.total_assigned_work_time, t),
            field3: formatTime(item.total_work_time, t),
            field4: formatTime(item.total_assigned_break, t),
            field5: formatTime(item.total_used_break, t),
            field6: formatTime(item.total_assigned_net_work_hours, t),
            field7: formatTime(item.total_net_work_hours, t),
          } : null;
    });

    setDataArr(dataArr);
  }, [data]);

  const detailReportColumnName = [
    {
      A: language("general.name"),
      B: language("general.date"),
      C: language("staff_detail.login_time"),
      D: language("staff_detail.logout_time"),
      // E: language("staff.status"),
    },
  ];

  const generalReportColumnName = [
    {
      A: language("general.name"),
      B: language("general.date"),
      C: language("staff_detail.assigned_work"),
      D: language("staff_detail.normal_work"),
      E: language("staff_detail.assigned_break"),
      F: language("staff_detail.break"),
      G: language("staff_detail.assigned_clear_work"),
      H: language("staff_detail.clear_work"),
    },
  ];

  return (
    <div className="border-0 d-flex justify-content-between align-items-center">
      <ExcelExport
        buggyColumnWidth={true}
        value={dataArr}
        tableOneColumn={station == 0 ? detailReportColumnName : generalReportColumnName}
        name={station == 1 ? t("staff_detail.personel") : station == 0 ? t("staff_detail.working_detail") : t("general.general_report")}
      />
    </div>
  );
};

const PersonelReport = () => {
  const searchInput = useRef(null);
  const { id } = useParams();
  const { t, i18n } = useTranslation("global");
  const { selectCompany } = useNotificationContext();
  const last5Days = moment().subtract(5, "d");
  const today = moment();
  const last5DaysFormat = last5Days.format("YYYY-MM-DD HH:mm:ss.SSSSSS Z");
  const todayFormat = today.format("YYYY-MM-DD HH:mm:ss.SSSSSS Z");
  const location = useLocation();
  const { selectedKeys } = location.state || {};
  const [disabledDates, setDisabledDates] = useState([last5Days, today]);
  const [date, setDate] = useState({
    start: last5DaysFormat,
    end: todayFormat,
  });
  const [initialValues, setFormValues] = useState({
    user: selectedKeys || [], // Eğer state mevcutsa kullanıcı seçili gelir
  });
  const [excelData, setExcelData] = useState();
  const [personnelData, setPersonnelData] = useState([]);
  const [personnelDetail, setPersonnelDetail] = useState();
  const [totalData, setTotalData] = useState([]);

  const { RangePicker } = DatePicker;

  const onRangeChange = (dates, dateStrings) => {
    setDisabledDates(dates);
    if (dates) {
      setDate({
        end: moment(dateStrings[1]).format("YYYY-MM-DD HH:mm:ss.SSSSSS Z"),
        start: moment(dateStrings[0]).format("YYYY-MM-DD HH:mm:ss.SSSSSS Z"),
      });
    } else {
      setDate({ start: last5DaysFormat, end: todayFormat });
    }
  };

  const getDetailData = async (serial) => {
    try {
      const data = await getRequestHttps(`v1/user-detail`, {
        company_id: selectCompany?.company_id,
        user_id: serial,
      });

      const formValue = {
        personnel_name: data?.name_surname,
        email: data?.email,
        position: data?.work_title,
        branch: data?.branch_name,
        department: data?.department_name,
        phone: data?.phone,
        status: data?.is_record_login,
        hours: data?.hours,
        days: data?.days || [],
        holiday: data?.holiday_credit,
      };
      setPersonnelDetail(formValue);
    } catch (error) { }
  };

  const getWorkDetailReport = async (serial) => {
    try {
      if (!Array.isArray(serial)) {
        serial = [serial]; // Tek bir değer dizisine dönüştürülüyor
      }
      let endpoint = "v1/work-report-general";
      let data;
      const promises = serial.map(async (serial) => {
        return await getRequestHttps(endpoint, {
          company_id: selectCompany?.company_id,
          user_id: serial,  // Her bir kullanıcı için ayrı ayrı çağırıyoruz
          limit: 1000,
          start_date: date.start,
          end_date: date.end,
        });
      });

      const results = await Promise.all(promises);
      data = results.flat(); // Dönüşleri düzleştir

      // Verileri işleme ve sıralama
      if (data) {
        data.sort((a, b) => Date.parse(a.login_datetime) > Date.parse(b.login_datetime) ? -1 : 1);
        const values = processLoginLogoutData(data);
        const groupData = groupDataByDate(values);
        const formattedData = [];
        for (const item of groupData) {
          for (const entry of item.value) {
            const formattedEntry = {
              user_name: entry.user_name,
              created_at: item.date,
              login_time: entry.login_datetime, // ? new Date(entry.login_datetime).setSeconds(0) : "",
              logout_time: entry.logout_datetime, // ? new Date(entry.logout_datetime).setSeconds(0) : "",
              branch: entry.branch_name,
              department: entry.department_name,
              status_type: !entry.logout_datetime ? false : true,
              description: entry.description,
              status: entry.is_logout_break ? "pending" : true,
              serial: entry.user_id,
            };
            formattedData.push(formattedEntry);
            console.log("Formatted Entry: ", formattedEntry);
          }
        }
        setPersonnelData(formattedData);
        setExcelData(formattedData);
        console.log("endpoint: ", endpoint);
        console.log("station: ", station);
        console.log("Data: ", data);
      }
    } catch (error) {
      setPersonnelData([]);
      setExcelData([]);
      console.log("error", error);
      console.log("Serial value: ", serial)
    }
  };

  const getWorkReport = async (serial) => {
    try {
      if (!Array.isArray(serial)) {
        serial = [serial]; // Tek bir değer dizisine dönüştürülüyor
      }
      let endpoint = "v1/work-time-report";
      let data;

      const promises = serial.map(async (serial) => {
        return await getRequestHttps(endpoint, {
          company_id: selectCompany?.company_id,
          user_id: serial,  // Her bir kullanıcı için ayrı ayrı çağırıyoruz
          limit: 1000,
          start_date: date.start,
          end_date: date.end,
        });
      });

      const results = await Promise.all(promises);
      data = results.flat(); // Dönüşleri düzleştir

      const formattedPersonnelData = [];
      const groupedData = data;
      for (const item of groupedData) {
        const personnelData = {
          user_name: item.user_name,
          created_at: item.created_at,
          login_time: item.login_datetime, // ? new Date(entry.login_datetime).setSeconds(0) : "",
          logout_time: item.logout_datetime, // ? new Date(entry.logout_datetime).setSeconds(0) : "",
          branch: item.branch_name,
          department: item.department_name,
          status_type: !item.logout_datetime ? false : true,
          description: item.description,
          status: item.is_logout_break ? "pending" : true,
          serial: item.user_id,
          assigned_work_time: item.assigned_work_time,
          work_time: item.work_time,
          assigned_break: item.assigned_break,
          used_break: item.used_break,
          assigned_net_work_hours: item.assigned_net_work_hours,
          net_work_hours: item.net_work_hours
        };

        formattedPersonnelData.push(personnelData);
        console.log("Personnel Data: ", personnelData);
      }
      setPersonnelData(formattedPersonnelData);
      setExcelData(formattedPersonnelData);
      console.log("data: ", data);
    } catch (error) {
      console.log("Error: ", error);
      console.log("Station: ", station);
    }
  }

  const getAggregatedData = async (serial) => {
    try {
      if (!Array.isArray(serial)) {
        serial = [serial]; // Tek bir değer dizisine dönüştürülüyor
      }
      let endpoint = "v1/work-time-report-general";
      let data;

      const promises = serial.map(async (serial) => {
        return await getRequestHttps(endpoint, {
          company_id: selectCompany?.company_id,
          user_id: serial,  // Her bir kullanıcı için ayrı ayrı çağırıyoruz
          limit: 1000,
          start_date: date.start,
          end_date: date.end,
        });
      });

      const results = await Promise.all(promises);
      data = results.flat(); // Dönüşleri düzleştir

      const formattedTotal = [];
      const groupedData = data;
      for (const item of groupedData) {
        const total = {
          user_name: item.user_name,
          created_at: item.created_at,
          login_time: item.login_datetime, // ? new Date(entry.login_datetime).setSeconds(0) : "",
          logout_time: item.logout_datetime, // ? new Date(entry.logout_datetime).setSeconds(0) : "",
          branch: item.branch_name,
          department: item.department_name,
          status_type: !item.logout_datetime ? false : true,
          description: item.description,
          status: item.is_logout_break ? "pending" : true,
          serial: item.user_id,
          total_assigned_break: item.total_assigned_break || 0,
          total_used_break: item.total_used_break || 0,
          total_work_time: item.total_work_time || 0,
          total_net_work_hours: item.total_net_work_hours || 0,
          total_assigned_work_time: item.total_assigned_work_time || 0,
          total_assigned_net_work_hours: item.total_assigned_net_work_hours || 0
        };

        formattedTotal.push(total);
        console.log("Total: ", total);
      }
      setTotalData(formattedTotal);
      console.log("data: ", data);
    } catch (error) {
      console.log("Error: ", error);
      console.log("Station: ", station);
    }
  }

  useEffect(() => {
    getDetailData(id);
    if (station === 1) {
      getWorkReport(id);
    }
    if (station === 0) {
      getWorkDetailReport(id);
    }
    if (station === 2) {
      getAggregatedData(id);
    }
  }, [id, i18n.language, date]);

  const columns = [
    {
      title: t("general.name"),
      dataIndex: "user_name",
      render: (text) => <div className="tableRowText" p style={{ color: "#3498db" }}>{text}</div>,
    },
    {
      title: t("general.description"),
      dataIndex: "description",
      render: (text) => <div className="tableRowText" p style={{ color: "#f39c12" }}>{text}</div>,
    },
    {
      title: t("general.date"),
      dataIndex: "created_at",
      width: "15%",
      render: (value) => (
        <div className="tableRowText" p style={{ color: "#1abc9c" }}>
          {moment(value).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      title: t("staff_detail.login_time"),
      dataIndex: "login_time",
      render: (value) => (
        <div className="tableRowText" p style={{ color: "#2980b9" }}>
          <ReportTimeHelper field={"login_time"} data={[{ login_time: value }]} />
        </div>
      ),
    },
    {
      title: t("staff_detail.logout_time"),
      dataIndex: "logout_time",
      render: (value) => (
        <div className="tableRowText" p style={{ color: "#e74c3c" }}>
          <ReportTimeHelper field={"logout_time"} data={[{ logout_time: value }]} />
        </div>
      ),
    },
  ].filter((column) => column.title);

  const columnsWorking = [
    {
      title: t("general.name"),
      dataIndex: "user_name",
      render: (text) => <div className="tableRowText" p style={{ color: "#3498db" }}>{text}</div>,
    },
    {
      title: t("general.date"),
      dataIndex: "created_at",
      width: "15%",
      render: (value) => (
        <div className="tableRowText" p style={{ color: "#f39c12" }}>
          {moment(value).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      title: t("staff_detail.assigned_work"),
      dataIndex: "assigned_work_time",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#2471a3" }}>{result}</div>;
      },
    },
    {
      title: t("staff_detail.normal_work"),
      dataIndex: "work_time",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#1abc9c" }}>{result}</div>;
      },
    },
    {
      title: t("staff_detail.assigned_break"),
      dataIndex: "assigned_break",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#8e44ad" }}>{result}</div>;
      },
    },
    {
      title: t("staff_detail.break"),
      dataIndex: "used_break",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#e74c3c" }}>{result}</div>;
      },
    },
    {
      title: t("staff_detail.assigned_clear_work"),
      dataIndex: "assigned_net_work_hours",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#1abc9c" }}>{result}</div>;
      },
    },
    {
      title: t("staff_detail.clear_work"),
      dataIndex: "net_work_hours",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#2ecc71" }}>{result}</div>;
      },
    },
  ];

  const columnsTotal = [
    {
      title: t("general.name"),
      dataIndex: "user_name",
      render: (text) => <div className="tableRowText" p style={{ color: "#3498db" }}>{text}</div>,
    },
    {
      title: t("staff_detail.assigned_work"),
      dataIndex: "total_assigned_work_time",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#f39c12" }}>{result}</div>;
      },
    },
    {
      title: t("staff_detail.normal_work"),
      dataIndex: "total_work_time",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#1abc9c" }}>{result}</div>;
      },
    },
    {
      title: t("staff_detail.assigned_break"),
      dataIndex: "total_assigned_break",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#e74c3c" }}>{result}</div>;
      },
    },
    {
      title: t("staff_detail.break"),
      dataIndex: "total_used_break",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#8e44ad" }}>{result}</div>;
      },
    },
    {
      title: t("staff_detail.assigned_clear_work"),
      dataIndex: "total_assigned_net_work_hours",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#1abc9c" }}>{result}</div>;
      },
    },
    {
      title: t("staff_detail.clear_work"),
      dataIndex: "total_net_work_hours",
      render: (value) => {
        const result = value > 0 ? formatTime(value, t) : formatTime(0, t);
        return <div className="tableRowText" p style={{ color: "#2ecc71" }}>{result}</div>;
      },
    },
  ];

  const [station, setStation] = useState(0);
  useEffect(() => {
    if (station === 0) {
      getWorkDetailReport(id);
    } if (station === 1) {
      getWorkReport(id);
    } if (station === 2) {
      getAggregatedData(id);
    }
  }, [station, id, date]);

  const tabsArray = [
    { id: 0, title: t("staff_detail.working_detail") },
    {
      id: 1,
      title: t("staff_detail.personel"),
    },
    { id: 2, title: t("general.general_report") },
  ];

  return (
    <div className="mt-5 pl-4 mr-5 flex flex-col">
      <div className="flex flex-col w-full">
        <div className="flex justify-between custom-border-b">
          <div className="flex text-sm md:text-base">
            {tabsArray?.map(({ id, title, image }, index) => (
              <div
                key={index}
                onClick={() => setStation(id)}
                className="flex flex-col items-center group mr-16"
              >
                <div className="flex items-center">
                  <p
                    className={`font-semibold group-hover:text-[#858f91b3] capitalize cursor-pointer ease-linear duration-300 ${id === station ? "text-[#858f91b3]" : "text-[#858f91b3]"
                      }`}
                    key={index}
                  >
                    {title}
                  </p>
                </div>

                <div
                  style={{ marginBottom: "-2px" }}
                  className={`w-[100%] ease-linear duration-300 group-hover:custom-border-b group-hover:border-[#009BDA] ${id === station ? "custom-border-a border-[#4B59EF] " : ""
                    }`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex sticky capitalize top-0 z-50 w-full flex-col mt-5 ms-3">
        <PersonelHeader data={personnelDetail} />
      </div>
      <div className="row mt-5 mb-3">
        <div className="col-6 custom-select">
          <div className="flex items-center gap-3">
            <RangePicker
              showTime
              presets={rangePresetsDevice}
              defaultValue={[last5Days, today]}
              size="middle"
              format="YYYY-MM-DD HH:mm"
              onChange={onRangeChange}
            />
          </div>
        </div>
        <div className="d-flex col-6 justify-content-end">
          <div className="text-[#009BDA]">
            <ExcelData
              data={station === 2 ? totalData : excelData}
              date={date}
              language={t}
              company={selectCompany}
              userName={personnelDetail?.personnel_name?.slice(0, 10)}
              station={station}
            />
          </div>
        </div>
      </div>

      {station == 0 && (
        <div className="bg-[#ffffff] w-full">
          <TableComponents
            bordered
            scroll={
              personnelData?.length > 2
                ? {
                  y: 550,
                }
                : {}
            }
            size="large"
            pagination={false}
            columns={columns.filter((item) => {
              return item?.title;
            })}
            data={personnelData}
          // virtual
          />
        </div>
      )}
      {station == 1 && (
        <div className="bg-[#ffffff] w-full">
          <TableComponents
            bordered={false}
            scroll={
              personnelData?.length > 10
                ? {
                  y: 550,
                }
                : {}
            }
            size="large"
            pagination={false}
            columns={columnsWorking.filter((item) => {
              return item?.title;
            })}
            data={personnelData}
          // virtual
          />
        </div>
      )}
      {station === 2 && (
        <div className="bg-[#ffffff] w-full mt-3">
          <TableComponents
            bordered={false}
            scroll={
              totalData?.length > 10
                ? {
                  y: 550,
                }
                : {}
            }
            size="large"
            pagination={false}
            columns={columnsTotal.filter((item) => {
              return item?.title;
            })}
            data={totalData}
          />
        </div>
      )}
    </div>
  );
};


export default PersonelReport;
