import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getRequestHttps, deleteRequestHttps } from '../../../libs/AllGetRequest/HttpsHelper';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { toast } from 'react-toastify';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal as AntdModal } from 'antd';

const ReceiverMessagesModal = ({ show, onCancel, receiverId, receiverName }) => {
    const [messages, setMessages] = useState([]);
    const [t, i18n] = useTranslation("global");
    const [visibleMessages, setVisibleMessages] = useState(5);

    const fetchReceiverMessages = async () => {
        try {
            const response = await getRequestHttps(`v1/sent-messages-user?receiver_id=${receiverId}`);
            setMessages(response); // Gelen mesajları state'e ata
        } catch (error) {
            console.error('Gönderici mesajları alınırken bir hata oluştu:', error);
        }
    };

    const handleShowMore = () => {
        setVisibleMessages(prevVisible => prevVisible + 5);
    }

    useEffect(() => {
        if (show && receiverId) {
            fetchReceiverMessages();
        }
    }, [show, receiverId]);

    const handleDelete = (messageId) => {
            AntdModal.confirm({
                title: t("general.is_delete"),
                content: t("general.undone"),
                okText: t("general.yes"),
                okType: "danger",
                cancelText: t("general.no"),
                zIndex: 1055,
                onOk: async () => {
                    try {
                        await toast.promise(
                            deleteRequestHttps(`v1/delete-sent-messages?message_id=${messageId}`),
                            {
                                pending: t("crud.general.deleting"),
                                success: t("crud.general.deleted"),
                                error: t("super_admin.add_error"),
                            }
                        );
    
                        // Mesajı listeden çıkar
                        setMessages((prevMessages) =>
                            prevMessages.filter((msg) => msg.id !== messageId)
                        );
                    } catch (error) {
                        console.error("Mesaj silinirken bir hata oluştu:", error);
                    }
                },
                onCancel: () => {
                    console.log("Silme işlemi iptal edildi.");
                },
            });
        };

    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{receiverName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {messages && messages.length > 0 ? (
                    <>
                        <ul className="list-group">
                            {messages.map(msg => (
                                <li key={msg.id} className="list-group-item"
                                    style={{
                                        display: "flex", // Flexbox düzeni
                                        justifyContent: "space-between", // Sol ve sağa hizala
                                        alignItems: "center", // Dikeyde ortala
                                        cursor: "pointer",
                                    }}>
                                    {/* Sol taraf: Zaman ve mesaj */}
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column", // Alt alta sıralama
                                        alignItems: "flex-start", // Sol hizalı tut
                                    }}>
                                        <small className="text-muted">
                                            {moment(msg.sent_at).format('YYYY-MM-DD HH:mm:ss')}
                                        </small>
                                        <strong>{msg.message}</strong>
                                    </div>
                                    {/* Sağ taraf: Silme butonu */}
                                    <button
                                        type="primary"
                                        onClick={() => handleDelete(msg.id)}
                                        style={{
                                            backgroundColor: "red",
                                            borderColor: "red",
                                            borderRadius: "10px",
                                            color: "white",
                                            width: "30px",
                                            height: "30px",
                                            display: "flex", // İkonu düzgün ortalamak için
                                            alignItems: "center", // Dikeyde ortala
                                            justifyContent: "center", // Yatayda ortala
                                            cursor: "pointer",
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </button>
                                </li>
                            ))}
                        </ul>
                        {visibleMessages < messages.length && ( // Daha fazla mesaj varsa butonu göster
                            <Button variant="link" onClick={handleShowMore}>
                                {t("general.show_more")}
                            </Button>
                        )}
                    </>
                ) : (
                    <p>{t("Message not found.")}</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button type="secondary" style={{ backgroundColor: "#808080", borderColor: "#808080", }} onClick={onCancel}>{t("general.close")}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReceiverMessagesModal;
